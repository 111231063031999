///
/// @file global/_functions.scss
///
/// \brief Functions
///
/// Useful functions to help us calculate various values
/// (Credit to Zurb Foundation, http://foundation.zurb.com/docs)
///

// RANGES
// We use these functions to define ranges for various things, like media queries.
@function lower-bound($range) {
  @if length($range) <= 0 {
    @return 0;
  }
  @return nth($range, 1);
}

@function upper-bound($range) {
  @if length($range) < 2 {
    @return 999999999999;
  }
  @return nth($range, 2);
}

@function is_2020_nav_redesign() {
  @if variable-exists('cr20_nav_refresh') and $cr20_nav_refresh == true {
    @return true;
  } @else {
    @return false;
  }
}

@function is_cr20_desktop_nav_refresh() {
  @if variable-exists('cr20_desktop_nav_refresh') and $cr20_desktop_nav_refresh == true {
    @return true;
  } @else {
    @return false;
  }
}

// Line height function to generate unitless numbers vs the px values in the styleguide.
@function get-line-height($font-size, $line-height) {
  @return $line-height / $font-size;
}

// rem function to convert pixels to rem
@function get-rem($size) {
  $remSize: $size / 16px;
  @return #{$remSize}rem;
}
